import Checkbox from "./Checkbox";
import { useData } from "../store";

const MoreActivity = ({ pnum, data }) => {
  const setActivityChecked = useData((state) => state.setActivityChecked);
  return (
    <div className="grid grid-cols-4 justify-items-start gap-4 px-4 py-3">
      <div className="flex items-center col-span-2 gap-6">
        <div className="h-5 w-5 border-b-[1.75px] border-l-[1.75px] border-[#e6e6e6] translate-x-[8px] translate-y-[-10px]"></div>
        <Checkbox
          className={"activity-checkbox"}
          title={data.title + " of Civil " + pnum}
          isChecked={data.checked}
          onChange={() => setActivityChecked(data.id)}
        />
        <span>{data.activityName}</span>
      </div>
      <span>567.80</span>
      <div className="flex items-center justify-between w-full">
        <span>₹ 2,98,6792</span>
        <img
          height={18}
          width={18}
          title="no action yet"
          className="cursor-pointer"
          src="https://img.icons8.com/ios-filled/18/expand-arrow--v1.png"
          alt="more activities"
        />
      </div>
    </div>
  );
};
export default MoreActivity;
