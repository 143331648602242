import { useState } from "react";

const SaveWorkorder = ({ cstate, stateOpen }) => {
  const [clientName, setClientName] = useState("");
  const [dateOfCommencement, setDateOfCommencement] = useState("");
  const [dateOfCompletion, setDateOfCompletion] = useState("");
  const [rfqCode, setRfqCode] = useState("");
  const doneClicked = () => {
    console.log(
      "Client Name : ",
      clientName,
      "\nDate of Commencement : ",
      dateOfCommencement,
      "\nDate of Completion : ",
      dateOfCompletion,
      "\nRFQ Code : ",
      rfqCode
    );
    if (!clientName || !dateOfCommencement || !dateOfCompletion || !rfqCode)
      console.log("Your Form Is Not Completed Yet!");
  };
  return (
    <div
      className={` ${
        !cstate
          ? "pointer-events-none bg-[#00000000] hidden-xxx"
          : "bg-[#00000040]"
      } save-workorder absolute top-0 left-0 flex justify-end w-full h-full overflow-x-hidden`}
    >
      <div
        className={` ${
          !cstate && "saveWorkorderCollpased"
        } save-workorder-overlay flex flex-col bg-white max-sm:min-w-full sm:min-w-96 gap-4 px-6 py-4`}
      >
        <div className="flex items-center justify-between gap-3">
          <span className="text-xl font-semibold">Workorder</span>
          <img
            className="cursor-pointer translate-x-1 p-0.5 rounded hover:shadow"
            height={20}
            width={20}
            src="https://img.icons8.com/material-rounded/24/multiply--v1.png"
            alt="go-back"
            title="go back"
            onClick={() => stateOpen(false)}
          />
        </div>
        <div className="flex-1 flex flex-col text-sm gap-4">
          <div className="client-input flex flex-col gap-2">
            <span className="text-xs font-medium">Client</span>
            <div className="cursor-pointer flex items-center justify-end">
              <select
                className="cursor-pointer w-full outline-0 border-2 rounded-lg appearance-none py-2.5 px-4"
                // defaultValue={"Client Name"}/
                value={clientName ? clientName : "Client Name"}
                onChange={(e) => setClientName(e.target.value)}
              >
                <option disabled hidden value={"Client Name"}>
                  Client Name
                </option>
                {["A", "B", "C"].map((opt) => (
                  <option key={"save-client-option-" + opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
              <img
                height={16}
                width={16}
                title="no action yet"
                className="absolute cursor-pointer mx-3.5 pointer-events-none"
                src="https://img.icons8.com/ios-filled/16/expand-arrow--v1.png"
                alt="more activities"
              />
            </div>
          </div>
          <div className="client-input flex flex-col gap-2">
            <span className="text-xs font-medium">Date of Commencement</span>
            <div className="cursor-pointer flex items-center justify-end">
              <input
                className="cursor-pointer w-full outline-0 border-2 rounded-lg appearance-none py-2.5 px-4"
                type="date"
                onChange={(e) => setDateOfCommencement(e.target.value)}
              />
            </div>
          </div>
          <div className="client-input flex flex-col gap-2">
            <span className="text-xs font-medium">Date of Completion</span>
            <div className="cursor-pointer flex items-center justify-end">
              <input
                className="cursor-pointer w-full outline-0 border-2 rounded-lg appearance-none py-2.5 px-4"
                type="date"
                onChange={(e) => setDateOfCompletion(e.target.value)}
              />
            </div>
          </div>
          <div className="client-input flex flex-col gap-2">
            <span className="text-xs font-medium">RFQ Code</span>
            <div className="cursor-pointer flex items-center justify-end">
              <input
                className="cursor-pointer w-full outline-0 border-2 rounded-lg appearance-none py-2.5 px-4"
                type="text"
                placeholder="RFQ Code"
                value={rfqCode}
                onChange={(e) => setRfqCode(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button
          className="self-end text-white text-sm font-semibold bg-[#60d1d0] hover:bg-[#4bbebd] border-none outline-none rounded-lg shadow-md shadow-blue-100 hover:shadow-gray-300 sm:min-w-52 py-2.5 px-4 mb-1"
          onClick={doneClicked}
        >
          Done
        </button>
      </div>
    </div>
  );
};
export default SaveWorkorder;
