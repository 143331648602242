import { useState } from "react";
import OverviewData from "./OverviewData";
import Checkbox from "./Checkbox";
import { useData } from "../store";

const WorkorderTabs = () => {
  const { data, checked, setAllChecked } = useData();
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <>
      <div className="tabs w-full">
        <div className="flex text-center text-lg">
          {["Overview", "Other"].map((tab, index) => (
            <span
              key={tab + "-tab-button"}
              className={`cursor-pointer ${
                currentTab === index + 1
                  ? "font-medium border-black"
                  : "text-[#b2b2b2] border-[#b2b2b2]"
              } border-b-[3px] min-w-56 w-56 p-2`}
              title={tab + " tab"}
              onClick={() => setCurrentTab(index + 1)}
            >
              {tab}
            </span>
          ))}
        </div>
      </div>
      {currentTab === 1 ? (
        <div className="tab-space text-sm font-medium flex flex-col border border-[#e6e6e6] divide-y divide-[#e6e6e6]">
          <div className="grid grid-cols-4 justify-items-start bg-[#deeafa] gap-4 px-4 py-3">
            <div className="flex col-span-2 gap-8">
              <Checkbox
                className={"packages-checkbox"}
                title={"select all packages"}
                isChecked={checked}
                onChange={() => setAllChecked()}
              />
              <span>Packages</span>
            </div>
            <span>
              Rates<span className="font-normal italic">{"(in sqft)"}</span>
            </span>
            <span>Total</span>
          </div>
          {data?.map((pkg, i) => (
            <OverviewData
              key={"overview-data-row-" + (i + 1)}
              num={i + 1}
              data={pkg}
            />
          ))}
        </div>
      ) : currentTab === 2 ? (
        console.log("Hello World!") || (
          <div className="p-4 italic font-semibold">Hello World!</div>
        )
      ) : (
        <></>
      )}
    </>
  );
};
export default WorkorderTabs;
