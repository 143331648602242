import { useState } from "react";
import SaveWorkorder from "./SaveWorkorder";
import WorkorderTabs from "./WorkorderTabs";
const CreateWorkorder = () => {
  const [saveExpanded, setSaveExpanded] = useState(false);
  return (
    <div className="CreateWorkorder flex flex-col gap-4 px-5 py-4 overflow-x-hidden-">
      <div className="flex justify-between">
        <div className="flex items-center gap-3.5">
          <img
            className="cursor-pointer w-3 h-5 object-cover"
            height={18}
            width={18}
            src="https://img.icons8.com/ios-glyphs/50/chevron-left.png"
            alt="go-back"
            title="go back"
          />
          <span className="text-xl font-semibold">Create Workorder</span>
        </div>
        <button
          className="text-white bg-[#60d1d0] hover:bg-[#4bbebd] border-none outline-none rounded-lg shadow-md shadow-blue-100 hover:shadow-gray-300 py-2 px-12"
          onClick={() => setSaveExpanded(true)}
        >
          Save
        </button>
      </div>
      <WorkorderTabs />
      <SaveWorkorder cstate={saveExpanded} stateOpen={setSaveExpanded} />
    </div>
  );
};
export default CreateWorkorder;
