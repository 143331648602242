import { useState } from "react";
import Checkbox from "./Checkbox";
import MoreActivity from "./MoreActivity";
import { useData } from "../store";

const OverviewData = ({ num, data }) => {
  const setPackageChecked = useData((state) => state.setPackageChecked);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-4 justify-items-start gap-4 px-4 py-3">
        <div className="flex items-center col-span-2 gap-8">
          <Checkbox
            className={"overview-checkbox"}
            title={"select '" + data.packageName + "'"}
            isChecked={data?.checked}
            onChange={() => setPackageChecked(data?.id)}
          />
          <span className="font-bold">{data?.packageName}</span>
        </div>
        <span>567.80</span>
        <div className="flex items-center justify-between w-full">
          <span>₹ 2,98,6792</span>
          <img
            height={20}
            width={20}
            title={
              expanded ? "collapse more activities" : "expand more activities"
            }
            className="cursor-pointer"
            src={`https://img.icons8.com/forma-bold-filled/20/60d1d0/${
              expanded ? "minus" : "plus"
            }-math.png`}
            alt="more activities"
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>
      {expanded &&
        data?.activities?.map((activity, i) => (
          <MoreActivity
            key={"more-activity-row-" + (i + 1)}
            pnum={num}
            data={activity}
          />
        ))}
    </div>
  );
};
export default OverviewData;
