const Checkbox = ({ className, title, isChecked, setChecked, onChange }) => {
  return (
    <div className={`${className} flex cursor-pointer`} title={title}>
      <input
        className="more-activity-checkbox cursor-pointer checked:border-[#bdbdbd]"
        type="checkbox"
        value={isChecked}
        onChange={() =>
          setChecked ? setChecked(!isChecked) : onChange ? onChange() : {}
        }
      />
      <svg
        className={`absolute w-4 h-4 mt-1 p-0.5 ${
          isChecked ? "block" : "hidden"
        } pointer-events-none translate-x-px translate-y-[-2px]`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </div>
  );
};
export default Checkbox;
