import { create } from "zustand";

export const useData = create((set) => ({
  data: Array.from({ length: 5 }, (_, ind) => ({
    id: "Civil-" + (ind + 1),
    packageName: "Civil " + (ind + 1),
    checked: false,
    activities: Array.from({ length: 4 }, (_, i) => ({
      id: "Civil-" + (ind + 1) + "-Activity-" + (i + 1),
      activityName: "Activity " + (i + 1),
      checked: false,
    })),
    checkedActivities: 0,
  })),
  checked: false,
  checkedPackages: 0,
  setActivityChecked: (id) =>
    set((state) => {
      let totalPckgChecks = 0;
      let newData = state.data.map((pckg) => {
        let totalChecks = 0;
        pckg.activities = pckg.activities.map((activity) => {
          if (activity.id === id) activity.checked = !activity.checked;
          if (activity.checked) totalChecks++;
          return activity;
        });
        pckg.checkedActivities = totalChecks;
        pckg.checked = totalChecks === 4;
        if (pckg.checked) totalPckgChecks++;
        return pckg;
      });
      return {
        data: newData,
        checkedPackages: totalPckgChecks,
        checked: totalPckgChecks === 5,
      };
    }),
  setPackageChecked: (id) =>
    set((state) => {
      let totalChecks = 0;
      let newData = state.data.map((pckg) => {
        if (pckg.id === id) {
          pckg.activities = pckg.activities.map((activity) => {
            activity.checked = !pckg.checked;
            return activity;
          });
          pckg.checked = !pckg.checked;
        }
        if (pckg.checked) totalChecks++;
        return pckg;
      });
      return {
        data: newData,
        checkedPackages: totalChecks,
        checked: totalChecks === 5,
      };
    }),
  setAllChecked: () =>
    set((state) => {
      let newData = state.data.map((pckg) => {
        pckg.checked = !state.checked;
        pckg.activities = pckg.activities.map((activity) => {
          activity.checked = !state.checked;
          return activity;
        });
        return pckg;
      });
      return {
        data: newData,
        checked: !state.checked,
        checkedPackages: state.checked ? 0 : 5,
      };
    }),
}));
